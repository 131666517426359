.Loader{
    position: absolute;
    top: 50%;
    left: 50%;
}
@keyframes rotation {
    from {
      -webkit-transform: rotate(359deg);
    }
    to {
      -webkit-transform: rotate(0deg);
    }
  }
svg * {
    -webkit-transform-origin: 50% 50%;
    -ms-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
  }
  
  .circle {
    -webkit-animation: rotation 8s infinite linear;
  }
  .circle_s {
    -webkit-animation: rotation 4s infinite linear;
  }
  