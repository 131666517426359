.blog_post {
  width: 800px;
  background-color: #021b43;
  color: #fff;
  height: auto;
  margin-top: 50px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.blog_post_title {
  max-width: 240px;
  font-size: 16px;
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.blog_post_image {
  height: 200px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
.blog_post_title button{
  width: 100px;
  height: 40px;
  border: none;
  border-radius: 5px;
  background-color: #31476e;
  color: #fff;
}
